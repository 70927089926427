import React from "react";

import Navbar from "../Components/Navbar";
import EmailEdit from "../Components/EmailEdit";

const Email = () => {
  return (
    <div>
      <div className="container">
        <Navbar />
        <EmailEdit />
      </div>
    </div>
  );
};

export default Email;