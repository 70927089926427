import { React, useEffect, useState  } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

const SERVER_URL = "http://192.168.176.17:5000";

const EmailEdit = () => {
    const [bodyData,setBodyData] = useState("");
    const [subject,setSubject] = useState("");

    
    useEffect(() => {
        async function fetchData() {
            
            const options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            };
            try {
                const response = await fetch(SERVER_URL+"/email", options);
                const data = await response.json();
                setBodyData(data[0]['body']);
                setSubject(data[0]['title']);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        // Call the async fetchData function
        fetchData();
    }, []);

    const handleChange = (content) => {
        setBodyData(content)
    }
    const handleSubjectChange = (event) => {
        setSubject(event.target.value)
    }
    const handleSaveEmail = async  () => {

        async function saveData() {
            
            const options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({"title":subject,"body":bodyData})
            };
            try {
                const response = await fetch(SERVER_URL+"/saveemail", options);
                if (!response.ok) {
                  throw new Error('Network response was not ok');
                }
                toast.success("Email Successfully saved", {
                    autoClose: true,
                    isLoading: false,
                  })
                const data = await response.json();
                
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        // Call the async saveData function
        saveData();

    }
    return (
        <>
        <div className="logs-container" style={{"overflow-y":"scroll","overflow-x":"hidden",}}>
            <ToastContainer theme="light" />
            <h1 className="header">Edit Email</h1>
            <div className="status-container" style={{padding: "20px", width: "auto"}}>
                <input type="text" id="subject" placeholder="Subject" value={subject} onChange={handleSubjectChange} />
                <SunEditor 
                    onChange={handleChange} 
                    setContents={bodyData} />
                <button className="btn" onClick={handleSaveEmail}>Save</button>
                <Link
                  className="btn"
                  to="/">
                  Back
                </Link>
            </div>
        </div>
        </>
    );
}
export default EmailEdit;